import React from "react";
import { ChevronCompactDown } from 'react-bootstrap-icons';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import { changeLanguage, translate } from "src/common/I18N";
import { getLanguageCode } from "src/common/Utils";

const LanguagePicker = () => {

    const languageMap: any = {
        US: "en-US",
        DE: "de-DE",
        CN: "zh-CN",
        JP: "ja-JP",
        KR: "ko-KR",
        IT: "it-IT",
        FR: "fr-FR",
        ES: "es-ES",
        PT: "pt-PT",
        RU: "ru-RU",
        BA: "bs-BA",
        RS: "sr-RS", 
        HR: "hr-HR",
        SI: "sl-SI",
        RO: "ro-RO",
        SK: "sk-SK",
        BG: "bg-BG",
        HU: "hu-HU",
        UA: "uk-UA",
        PL: "pl-PL",
        BE: "nl-BE",
        NL: "nl-NL",
        CZ: "cs-CZ",
        DK: "da-DK",
        NO: "nb-NO",
        SE: "sv-SE",
        FI: "fi-FI",
        GR: "el-GR",
        TR: "tr-TR",
        ID: "id-ID",
        MY: "ms-MY",
        TH: "th-TH",
        PH: "fil-PH",
        VN: "vi-VN",
        IN: "hi-IN",
        NP: "ne-NP",
    };

    // this is used for the icon, we use the side language parameter to look up the two letter country code in languageMap 
    const languageMapKey = Object.keys(languageMap).find(key => languageMap[key] === getLanguageCode()) || "";

    const onLanguageSelectHandler = (lang: string) => {
        changeLanguage(languageMap[lang]);
    };

    const getMenuItem = (countryCode: string, language: string) => {
        return (
            <button onClick={() => onLanguageSelectHandler(countryCode)} className={`list-group-item list-group-item-action border-0 text-center`}>
                <img className="d-inline-block me-1" width="24" height="16" src={`assets/flags/${countryCode}.svg`}/>
                 <br/>
                <span>{translate(ds[language].stringId, ds[language].defaultString)}</span>
            </button>
        );
    };

    return (
        <div className="language-picker">
                <button type="button" id="Popover1" area-label={translate(ds.languagePickerLabel.stringId, ds.languagePickerLabel.defaultString)} className="btn language-picker-btn text-decoration-none link-info p-0">
                    <span className="me-1">{translate(ds.languageSwither.stringId, ds.languageSwither.defaultString)}</span>
                    <img className="d-inline-block me-1" width="24" height="16" src={`assets/flags/${languageMapKey}.svg`}/>
                    <span className="me-1">{translate(`languageswitcher.languages.${languageMapKey.toLowerCase()}`, "")}</span>
                    <ChevronCompactDown size={22} />
                </button>
                <UncontrolledPopover
                    target="Popover1"
                    trigger="legacy"
                    placement="auto"
                >
                    <PopoverBody>
                        <>
                        <div className="row">
                            <div className="col-4">
                                <div className="list-group mb-2">
                                    {getMenuItem("US", "english")}
                                    {getMenuItem("DE", "german")}
                                    {getMenuItem("CN", "chinese")}
                                    {getMenuItem("JP", "japanese")}
                                    {getMenuItem("KR", "korean")}
                                    {getMenuItem("IT", "italian")}
                                    {getMenuItem("FR", "french")}
                                    {getMenuItem("ES", "spanish")}
                                    {getMenuItem("PT", "portugeas")}
                                    {getMenuItem("RU", "russia")}
                                    {getMenuItem("BA", "bosnian")}
                                    {getMenuItem("RS", "serbian")}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="list-group mb-2">
                                    {getMenuItem("HR", "croatian")}
                                    {getMenuItem("SI", "slovenian")}
                                    {getMenuItem("RO", "romanian")}
                                    {getMenuItem("SK", "slovak")}
                                    {getMenuItem("BG", "bulgarian")}
                                    {getMenuItem("HU", "hungarian")}
                                    {getMenuItem("UA", "ukrainian")}
                                    {getMenuItem("PL", "polish")}
                                    {getMenuItem("BE", "belgiun")}
                                    {getMenuItem("NL", "dutch")}
                                    {getMenuItem("CZ", "czech")}
                                    {getMenuItem("DK", "danish")}
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="list-group mb-2">
                                    {getMenuItem("NO", "norwegian")}
                                    {getMenuItem("SE", "swedish")}
                                    {getMenuItem("FI", "finnish")}
                                    {getMenuItem("GR", "greek")}
                                    {getMenuItem("TR", "turkish")}
                                    {getMenuItem("ID", "indonesian")}
                                    {getMenuItem("MY", "malay")}
                                    {getMenuItem("TH", "thai")}
                                    {getMenuItem("PH", "filipino")}
                                    {getMenuItem("VN", "vietnamese")}
                                    {getMenuItem("IN", "hindi")}
                                    {getMenuItem("NP", "nepali")}
                                </div>
                            </div>


                        </div>
                        </>
                    </PopoverBody>
                </UncontrolledPopover>
        </div>
    );
};

export default LanguagePicker;