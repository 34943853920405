import React from "react";
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";
import { Links } from "src/common/Links";



const luffyPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Luffy"}>
          <div className="miaru-luffy-page">
              <div className="">
                <div className="container">
                  <div className="row py-5">
                      <div className="col text-lg-start">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12 mb-2">
                                    <h1 className="lh-1 mb-3">
                                      {translate(ds.luffyPageSec1Title.stringId, ds.luffyPageSec1Title.defaultString)}
                                    </h1>
                                    <h4 className="">
                                      {translate(ds.luffyPageSec1SubHeading.stringId, ds.luffyPageSec1SubHeading.defaultString)}
                                    </h4>
                                    <p>
                                    {translate(ds.luffyPageSec1Text.stringId, ds.luffyPageSec1Text.defaultString)}
                                    </p>
                                </div>
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <img title={translate(ds.luffyPageSec1Img.stringId, ds.luffyPageSec1Img.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/concept_1.png"/>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="miaru-bg-light">
                    <div className="row g-0 align-items-center">
                        <div className="col">
                            <img title={translate(ds.luffyPageImg.stringId, ds.luffyPageImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/pose7.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyPageImg.stringId, ds.luffyPageImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/pose19.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyPageImg.stringId, ds.luffyPageImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/pose17.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyPageImg.stringId, ds.luffyPageImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/pose18.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyPageImg.stringId, ds.luffyPageImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/pose0.png"/>
                        </div>
                    </div>
              </div>

              <div className="">
                <div className="container">
                  <div className="row py-5">
                    <div className="col text-lg-start">
                      <div className="container">
                          <div className="row g-5 align-items-center">
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <img title={translate(ds.luffyPageSec2Img.stringId, ds.luffyPageSec2Img.defaultString) as string} className="mission-img img-fluid mx-auto d-block" src="assets/img/mascot/concept_2.png"/>
                              </div>
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <h1 className="lh-1 mb-3">
                                  {translate(ds.luffyPageSec2Title.stringId, ds.luffyPageSec2Title.defaultString)}
                                  </h1>
                                  <h4 className="">
                                  {translate(ds.luffyPageSec2SubHeading.stringId, ds.luffyPageSec2SubHeading.defaultString)}
                                  </h4>
                                  <p>
                                  {translate(ds.luffyPageSec2Text.stringId, ds.luffyPageSec2Text.defaultString)}
                                  </p>                               
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="miaru-bg-light">
                <div className="container">
                  <div className="row py-5">
                      <div className="col text-lg-start">
                        <div className="container">
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-6 col-md-12 mb-2">
                                    <h1 className="lh-1 mb-3">
                                      {translate(ds.luffyPageSec3Title.stringId, ds.luffyPageSec3Title.defaultString)}
                                    </h1>
                                    <h4 className="">
                                      {translate(ds.luffyPageSec3SubHeading.stringId, ds.luffyPageSec3SubHeading.defaultString)}
                                    </h4>
                                    <p>
                                    {translate(ds.luffyPageSec3Text.stringId, ds.luffyPageSec3Text.defaultString)}
                                    </p>
                                    <a href={Links.luffyNft} className="btn btn-primary btn-lg d-block mx-auto position-relative app-store-btn">
                                      {translate(ds.luffyPageSec3LinkText.stringId, ds.luffyPageSec3LinkText.defaultString)}
                                    </a>
                                </div>
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <img title={translate(ds.luffyPageSec3Img.stringId, ds.luffyPageSec3Img.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/nft_collage.png"/>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </ErrorBoundary>
    );
};

export default luffyPage;
