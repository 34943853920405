import React from "react";
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";



const TeamPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Team"}>
          <div className="miaru-team-page">
            <div className="miaru-bg-light">
              <div className="container">
                <div className="row pb-2">
                    <div className="col text-lg-start">
                      <div className="container">
                          <div className="row pb-3 align-items-center">
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <h1 className="lh-1 mb-3">
                                    {translate(ds.teamPageTitle.stringId, ds.teamPageTitle.defaultString)}
                                  </h1>
                                  <p>
                                  {translate(ds.teamPageText.stringId, ds.teamPageText.defaultString)}
                                  </p>
                              </div>
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <img title={translate(ds.teamPageImg.stringId, ds.teamPageImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/test3.png"/>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="">
              <div className="container">
                <div className="row pt-5 pb-2">
                  <div className="col text-lg-start">
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2 order-2 order-lg-1">
                                <img title={translate(ds.teamPageMissionImg.stringId, ds.teamPageMissionImg.defaultString) as string} className="mission-img img-fluid mx-auto d-block" src="assets/img/mascot/test5.png"/>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2 order-1 order-lg-2">
                                <h1 className="lh-1 mb-3">
                                {translate(ds.teamPageMissionTitle.stringId, ds.teamPageMissionTitle.defaultString)}
                                </h1>
                                <p>
                                {translate(ds.teamPageMissionText.stringId, ds.teamPageMissionText.defaultString)}
                                </p>
                                <ul>
                                  <li>{translate(ds.teamPageMissionTextB1.stringId, ds.teamPageMissionTextB1.defaultString)}</li>
                                  <li>{translate(ds.teamPageMissionTextB2.stringId, ds.teamPageMissionTextB2.defaultString)}</li>
                                  <li>{translate(ds.teamPageMissionTextB3.stringId, ds.teamPageMissionTextB3.defaultString)}</li>
                                </ul>
                                <p>
                                {translate(ds.teamPageMissionSecondText.stringId, ds.teamPageMissionSecondText.defaultString)}
                                </p>
                                <p>
                                {translate(ds.teamPageMissionThirdText.stringId, ds.teamPageMissionThirdText.defaultString)}
                                </p>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>

          </div>
        </ErrorBoundary>
    );
};

export default TeamPage;
