export const Links = {
    facebook: "https://www.facebook.com/people/Miaru/100093004904252/",
    twitter: "https://twitter.com/Miaru_co",
    instagram: "https://www.instagram.com/miaru_co/",
    youtube: "https://www.youtube.com/@miaru_co",
    tiktok: "https://www.tiktok.com/@miaru735", 
    linkedin: "https://www.linkedin.com/company/miaru/",
    medium: "https://miaru.medium.com/",
    store: "https://shop.miaru.co/",
    email: "mailto:contact@miaru.co",
    discord: "https://discord.gg/Sf43AvF9qf",
    android: "https://play.google.com/", // need to create still
    ios: "https://apps.apple.com/", // need to create still 
    pressKit: "https://drive.google.com/drive/folders/1b_I3eyKYH_yWK-ulN1nILTjsAVOxXKQe?usp=sharing",
    nftSale: "https://opensea.io",
    webApp: (window as any).miaruConfig.miaruWebAppLink, 


    // app links 
    home: "/",
    courses: "/courses",
    team: "/team",
    brand: "/brand",
    press: "/press",
    luffy: "/luffy",
    luffyNft: "/luffy-nft",
    faq: "/faq",
    community: "/assets/pdf/Miaru_Community_Guidelines.pdf",
    privacy: "/assets/pdf/Miaru_Privacy_Policy.pdf",
    terms: "/assets/pdf/Miaru_Terms_and_Conditions.pdf",

};