import React from "react";
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";



const BrandPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Brand"}>
          <div className="miaru-brand">
              <div className="">
              <div className="container">
                <div className="row py-5">
                  <div className="col text-lg-start">
                    <h1 className="fw-bold lh-1 mb-3">
                      {translate(ds.brandPageTitle.stringId, ds.brandPageTitle.defaultString)}
                    </h1>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        <u>
                            {translate(ds.brandPageLogo.stringId, ds.brandPageLogo.defaultString)}
                        </u>
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <img title={translate(ds.brandPageLogo.stringId, ds.brandPageLogo.defaultString) as string} className="logo" src="assets/logo.png"/>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2 miaru-bg">
                                <img title={translate(ds.brandPageLogo.stringId, ds.brandPageLogo.defaultString) as string} className="logo" src="assets/logo-white.png"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        <u>
                            {translate(ds.brandPageColor.stringId, ds.brandPageColor.defaultString)}
                        </u>
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#0D2144"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #0D2144
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Oxford Blue
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#2764CE"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #2764CE
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        True Blue
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#5CB3FF"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #5CB3FF
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Argentinian Blue
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#C2E9FF"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #C2E9FF
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Uranian Blue
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#E1F4FF"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #E1F4FF
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Alice Blue
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#F0FAFF"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #F0FAFF
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Ice Blue
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#FFFFFF"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #FFFFFF
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        White
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#81E773"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #81E773
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Light Green
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#F25454"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #F25454
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Bittersweet
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6 mb-2">
                                <Card>
                                    <div className="brand-color-container card-img-top" style={{backgroundColor: "#FFE270"}} />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                        #FFE270
                                        </CardTitle>
                                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        Naples Yellow
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
    
              </div>
              </div>
          </div>
        </ErrorBoundary>
    );
};

export default BrandPage;
