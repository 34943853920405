import React from "react";
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";



const TermsPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Mission"}>
          <div className="miaru-mission">
              <div className="">
              <div className="container">
                <div className="row py-5">
                  <div className="col text-lg-start">
                    <h1 className="fw-bold lh-1 mb-3">
                      {translate(ds.termsPageTitle.stringId, ds.termsPageTitle.defaultString)}
                    </h1>
                  </div>
                </div>
              </div>
              </div>

          </div>
        </ErrorBoundary>
    );
};

export default TermsPage;
