import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from "react-i18next";
import { getLanguageCode, storeData } from "src/common/Utils";


i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        lng: getLanguageCode(),
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
        load: 'currentOnly', 
        fallbackLng: "en-US",
        keySeparator: ".", 
        interpolation: {
            escapeValue: false,
        }
    });
export default i18n;

export const changeLanguage = (locale: string) => {
    storeData("site-language", {lang: locale});
    i18n.changeLanguage(locale);
}; 

export const translate = (stringId: string, defaultString: string, replacement: any = {}) => {
    const { t } = useTranslation();
    const translatedString = t(stringId, {defaultValue: defaultString, ...replacement}) as string;

    if (translatedString === "") {
        return defaultString;
    } else {
        return translatedString;
    }
};
