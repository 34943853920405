import React from "react";
import { Apple, GooglePlay } from 'react-bootstrap-icons';
import { Modal, ModalBody } from 'reactstrap';
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";
import { Links } from "src/common/Links";
import { getLanguageCode, isValidEmail } from "src/common/Utils";


const AppPage = () => {
  const [emailState, setEmailState] = React.useState("");
  const [emailInputError, setEmailInputError] = React.useState(false);
  const [showCaptcha, setShowCaptcha] = React.useState(false);
  const languageCode = getLanguageCode();


 const postData = async (url = "", data = {}) => {
  let response;
  try {
    response = await (window as any).AwsWafIntegration.fetch(url, {
      method: "POST", 
      body: JSON.stringify(data),
    });
  } catch (error) {
      console.log(error);
      return;
  }

  if (response.status === 405) {
    showMyCaptcha();
    return;
  }

  return response.json();
};

  const captchaExampleSuccessFunction = () => {
    setEmailInputError(false);
    postData((window as any).miaruConfig.emailApiGateway, { email: emailState }).then((data) => {
      setShowCaptcha(false);
      removeCaptcha();
    });
  };

  // refreshes the captcha if it was entered wrong 
  const captchaExampleErrorFunction = () => {
    showMyCaptcha();
  };

  const removeCaptcha = () => {
    const container = document.querySelector("#miaru-captcha-container");
    (container as any).innerHTML = "";
  };

  const showMyCaptcha = () => {
    const container = document.querySelector("#miaru-captcha-container");
  
    (window as any).AwsWafCaptcha.renderCaptcha(container, {
      apiKey: (window as any).miaruConfig.captchaKey,
      onSuccess: captchaExampleSuccessFunction,
      onError: captchaExampleErrorFunction,
      skipTitle: true,
    });
  };

    const onSubmitHandler = (e: any) => {
        e.preventDefault();
        if (e.target.elements[0].value !== "" && isValidEmail(e.target.elements[0].value)) {
          setEmailState(e.target.elements[0].value);
          setEmailInputError(false);
          setShowCaptcha(true);
        } else {
          setEmailInputError(true);
        }
    };

    return (
        <ErrorBoundary type={"Miaru-Landing"}>
          <div className="miaru-landing">
              {/** Intro Section Start */}
              <div className="miaru-bg">
              <div className="container">
                <div className="row align-items-center py-5 miaru-bg">
                  <div className="col-lg-7 text-center text-lg-start text-info">
                    <h1 className="fw-bold lh-1 mb-3">
                      <img title={translate(ds.mainFrontPageTopSectionTitle.stringId, ds.mainFrontPageTopSectionTitle.defaultString) as string} className="main-logo" src="assets/logo.png"/>
                    </h1>
                    <h4 className="mb-3">{translate(ds.mainFrontPageTopSectionHeading.stringId, ds.mainFrontPageTopSectionHeading.defaultString)}</h4>
                    <p>{translate(ds.mainFrontPageTopSectionSubHeading.stringId, ds.mainFrontPageTopSectionSubHeading.defaultString)}</p>
                    
                    <form className="row needs-validation" onSubmit={(e: any) => onSubmitHandler(e)}>
                      <div className="col-9">
                          <input type="email" className={"form-control form-control-lg " + (emailInputError ? "is-invalid" : "")} id="validationEmail" required={true} placeholder={translate(ds.mainFrontPageTopSectionEmailInputPlaceholder.stringId, ds.mainFrontPageTopSectionEmailInputPlaceholder.defaultString)} />
                          <div className="invalid-feedback">
                              {translate(ds.mainFrontPageTopSectionEmailInputError.stringId, ds.mainFrontPageTopSectionEmailInputError.defaultString)}
                          </div>
                      </div>

                      <div className="col-3">
                        <div className="d-grid gap-2">
                          <button type="submit" className="btn btn-primary btn-lg">{translate(ds.mainFrontPageTopSectionEmailSubmitButtonText.stringId, ds.mainFrontPageTopSectionEmailSubmitButtonText.defaultString)}</button>
                        </div>
                      </div>
                    </form>
                    <Modal trapFocus={true} isOpen={showCaptcha} onOpened={() => { showMyCaptcha(); }}>
                      <ModalBody className="mt-3 mb-3">
                          <div id="miaru-captcha-container" />
                      </ModalBody>
                    </Modal>


                  </div>
                  <div className="col-lg-5">
                    <img src="assets/img/mascot/miaru_country_bubbles.png" className="img-fluid" title={translate(ds.mainFrontPageTopSectionImageAltText.stringId, ds.mainFrontPageTopSectionImageAltText.defaultString)}/>
                  </div>
                </div>
              </div>
              </div>
              {/** Intro Section End */}

            {/** Lifetime subscription Section Start */}
            <div className="text-bg-secondary">
            <div className="container">
              <div className="row align-items-center py-5">
                <div className="col-lg-7 col-sm-12">
                      <img src="assets/img/mascot/miaru_celebration.png" className="miaru-phone-section-img img-fluid mx-auto d-block" title={translate(ds.mainFrontPagelifeSecImageAltText.stringId, ds.mainFrontPagelifeSecImageAltText.defaultString)}/>
                </div>
                <div className="col-lg-5 col-sm-12 text-center text-lg-start">
                  <h1 className="fw-bold lh-1 mb-3 miaru-text">{translate(ds.mainFrontPagelifeSecHeading.stringId, ds.mainFrontPagelifeSecHeading.defaultString)}</h1>
                  <h4 className="mb-3 miaru-text">{translate(ds.mainFrontPagelifeSecSubHeading.stringId, ds.mainFrontPagelifeSecSubHeading.defaultString)}</h4>
                  <h4 className="mb-3 miaru-text">{translate(ds.mainFrontPagelifeSecSubHeading2.stringId, ds.mainFrontPagelifeSecSubHeading2.defaultString)}</h4>
                  <p className="miaru-text">{translate(ds.mainFrontPagelifeSecText.stringId, ds.mainFrontPagelifeSecText.defaultString)}</p>
                  <div className="row justify-content-center gy-2 mb-4 mx-4">
                    <div className="col-12">
                      <a href={`${Links.webApp}?site-language=${languageCode}`} target="_blank" className="btn btn-primary btn-lg d-block mx-auto position-relative app-store-btn">
                        <span className="position-absolute top-0 start-0 xs-start-90 translate-middle badge rounded-pill bg-info">{translate(ds.mainFrontPageMobSecCommingSoon.stringId, ds.mainFrontPageMobSecCommingSoon.defaultString)}</span>
                        {translate(ds.mainFrontPagelifeSecButtonText.stringId, ds.mainFrontPagelifeSecButtonText.defaultString)}
                      </a>
                    </div>
                  </div>
                  <a href={Links.faq} className="link-primary">{translate(ds.mainFrontPagelifeSecMoreInfoLinkText.stringId, ds.mainFrontPagelifeSecMoreInfoLinkText.defaultString)}</a>
                </div>
              </div>
            </div>
            </div>
            {/** Lifetime subscription Section End */}

            {/** Mobile app Section Start */}
            <div className="container">
              <div className="row align-items-center py-5">
                <div className="col-lg-5 col-sm-12 text-center text-lg-start">
                  <h1 className="fw-bold lh-1 mb-3 miaru-text">{translate(ds.mainFrontPageMobSecHeading.stringId, ds.mainFrontPageMobSecHeading.defaultString)}</h1>
                  <h4 className="mb-3 miaru-text">{translate(ds.mainFrontPageMobSecSubHeading.stringId, ds.mainFrontPageMobSecSubHeading.defaultString)}</h4>
                  <p className="miaru-text">{translate(ds.mainFrontPageMobSecText.stringId, ds.mainFrontPageMobSecText.defaultString)}</p>
                </div>
                <div className="col-lg-7 col-sm-12">
                  <div className="row justify-content-center mb-3">
                    <div className="col">
                      <img title={translate(ds.mainFrontPageMobSecImageAltText.stringId, ds.mainFrontPageMobSecImageAltText.defaultString)} src="assets/img/mascot/miaru_phone_word_bubbles.png" className="miaru-phone-section-img img-fluid mx-auto d-block" />
                    </div>
                  </div>
                  <div className="row justify-content-center gy-2">
                    <div className="col-lg-4 col-sm-6 px-4">
                        <a href={Links.ios} target="_blank" className="btn btn-outline-primary btn-lg d-block mx-auto position-relative app-store-btn">
                              <span className="position-absolute top-0 start-0 xs-start-90 translate-middle badge rounded-pill bg-secondary">{translate(ds.mainFrontPageMobSecCommingSoon.stringId, ds.mainFrontPageMobSecCommingSoon.defaultString)}</span>
                              <div className="row">
                                <div className="col app-store-icon px-0 pt-1 mx-2">
                                    <Apple size={34} />
                                </div>
                                <div className="col text-start">
                                  <div className="text-line-1">{translate(ds.mainFrontPageMobSecAppleButtonTop.stringId, ds.mainFrontPageMobSecAppleButtonTop.defaultString)}</div>
                                  <div className="text-line-2">{translate(ds.mainFrontPageMobSecAppleButtonBottom.stringId, ds.mainFrontPageMobSecAppleButtonBottom.defaultString)}</div>
                                </div>
                              </div>
                          </a>
                    </div>
                    <div className="col-lg-4 col-sm-6 px-4">
                        <a href={Links.android} target="_blank" className="btn btn-outline-primary btn-lg d-block mx-auto position-relative app-store-btn">
                            <span className="position-absolute top-0 start-0 xs-start-90 translate-middle badge rounded-pill bg-secondary">{translate(ds.mainFrontPageMobSecCommingSoon.stringId, ds.mainFrontPageMobSecCommingSoon.defaultString)}</span>
                            <div className="row">
                              <div className="col app-store-icon px-0 pt-1 mx-2">
                                  <GooglePlay size={34} />
                              </div>
                              <div className="col text-start">
                                <div className="text-line-1">{translate(ds.mainFrontPageMobSecGoogleButtonTop.stringId, ds.mainFrontPageMobSecGoogleButtonTop.defaultString)}</div>
                                <div className="text-line-2">{translate(ds.mainFrontPageMobSecGoogleButtonBottom.stringId, ds.mainFrontPageMobSecGoogleButtonBottom.defaultString)}</div>
                              </div>
                            </div>
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/** Mobile app Section End */}


            {/** Adaptive Scenario Section Start */}
            <div className="miaru-bg-light">
            <div className="container">
              <div className="row align-items-center py-5">
                <div className="col-lg-7 col-sm-12">
                  <div className="row justify-content-center">
                    <div className="col">
                      <img title={translate(ds.mainFrontPageAiSecImageAltText.stringId, ds.mainFrontPageAiSecImageAltText.defaultString)} src="assets/img/mascot/ai-learning.png" className="miaru-phone-section-img img-fluid mx-auto d-block" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-12 text-center text-lg-start">
                <h1 className="fw-bold lh-1 mb-3 miaru-text">{translate(ds.mainFrontPageAiSecHeading.stringId, ds.mainFrontPageAiSecHeading.defaultString)}</h1>
                  <h4 className="mb-3 miaru-text">{translate(ds.mainFrontPageAiSecSubHeading.stringId, ds.mainFrontPageAiSecSubHeading.defaultString)}</h4>
                  <p className="miaru-text">{translate(ds.mainFrontPageAiSecText.stringId, ds.mainFrontPageAiSecText.defaultString)}</p>
                </div>
              </div>
            </div>
            </div>
            {/** Adaptive Scenario  Section End */}

            {/** Luffy NFT Section Start */}
            <div className="">
            <div className="container">
              <div className="row align-items-center py-5">
              <div className="col-lg-5 col-sm-12 text-center text-lg-start">
                  <h1 className="fw-bold lh-1 mb-3 miaru-text">{translate(ds.mainFrontPageLuffyNftSecHeading.stringId, ds.mainFrontPageLuffyNftSecHeading.defaultString)}</h1>
                  <h4 className="mb-3 miaru-text">{translate(ds.mainFrontPageLuffyNftSecSubHeading.stringId, ds.mainFrontPageLuffyNftSecSubHeading.defaultString)}</h4>
                  <p className="miaru-text">{translate(ds.mainFrontPageLuffyNftSecText.stringId, ds.mainFrontPageLuffyNftSecText.defaultString)}</p>
                  <div className="row justify-content-center gy-2 mb-4">
                    <div className="col-12">
                      <a href={Links.luffyNft} className="btn btn-primary btn-lg d-block mx-auto position-relative app-store-btn">
                        {translate(ds.mainFrontPageLuffyNftSecShopButton.stringId, ds.mainFrontPageLuffyNftSecShopButton.defaultString)}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-sm-12">
                      <img src="assets/img/mascot/nft_collage.png" className="miaru-phone-section-img img-fluid mx-auto d-block" title={translate(ds.mainFrontPageLuffyNftSecImageAltText.stringId, ds.mainFrontPageLuffyNftSecImageAltText.defaultString)}/>
                </div>
              </div>
            </div>
            </div>
            {/** Luffy NFT Section End */}

            {/** Shop Section Start */}
            <div className="text-bg-secondary">
            <div className="container">
              <div className="row align-items-center py-5">
                <div className="col-lg-7 col-sm-12">
                  <div className="row justify-content-center">
                    <div className="col">
                      <img title={translate(ds.mainFrontPageMerchSecImageAltText.stringId, ds.mainFrontPageMerchSecImageAltText.defaultString)} src="assets/img/mascot/miaru_merch.png" className="miaru-phone-section-img img-fluid mx-auto d-block" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-12 text-center text-lg-start">
                  <h1 className="fw-bold lh-1 mb-3 miaru-text">{translate(ds.mainFrontPageMerchSecHeading.stringId, ds.mainFrontPageMerchSecHeading.defaultString)}</h1>
                  <h4 className="mb-3 miaru-text">{translate(ds.mainFrontPageMerchSecSubHeading.stringId, ds.mainFrontPageMerchSecSubHeading.defaultString)}</h4>
                  <p className="miaru-text">{translate(ds.mainFrontPageMerchSecText.stringId, ds.mainFrontPageMerchSecText.defaultString)}</p>
                  <a href={Links.store} target="_blank" className="btn btn-primary btn-lg d-block mx-auto app-store-btn">{translate(ds.mainFrontPageMerchSecShopButton.stringId, ds.mainFrontPageMerchSecShopButton.defaultString)}</a>
                </div>
              </div>
            </div>
            </div>
            {/** Shop Section End */}

          </div>
        </ErrorBoundary>
    );
};

export default AppPage;
