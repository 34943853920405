import React from "react";
import { ArrowRightSquareFill } from 'react-bootstrap-icons';
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";



const CoursesPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Courses"}>
          <div className="miaru-courses">
              <div className="">
              <div className="container">
                <div className="row py-5">
                  <div className="col text-lg-start">
                    <h1 className="fw-bold lh-1 mb-3">
                      {translate(ds.coursePageTitle.stringId, ds.coursePageTitle.defaultString)}
                    </h1>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        <u>
                            {translate(ds.coursePageEnglish.stringId, ds.coursePageEnglish.defaultString)}
                        </u>
                    </h3>
                    <div className="container">
                        <div className="row pb-3 text-center align-items-center">
                            <div className="col">
                                <h5 className="lh-1 mb-3">
                                    {translate(ds.coursePageSpeak.stringId, ds.coursePageSpeak.defaultString)}
                                </h5>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/DE.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/CN.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/JP.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/KR.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/IT.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/FR.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/ES.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/PT.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/RU.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/BA.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/RS.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/HR.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/SI.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/RO.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/SK.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/BG.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/HU.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/UA.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/PL.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/BE.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/NL.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/CZ.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/DK.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/NO.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/SE.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/FI.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/GR.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/TR.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/ID.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/MY.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/TH.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/PH.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/VN.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/IN.svg"/>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/NP.svg"/>
                            </div>
                            <div className="col miaru-text">
                                <ArrowRightSquareFill size={40} />
                            </div>
                            <div className="col">
                                <h5 className="lh-1 mb-3">
                                    {translate(ds.coursePageLearn.stringId, ds.coursePageLearn.defaultString)}
                                </h5>
                                <img className="d-inline-block me-1"  width="60" height="40" src="assets/flags/US.svg"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        <u>
                            {translate(ds.coursePageJapanese.stringId, ds.coursePageJapanese.defaultString)}
                        </u>
                    </h3>
                    <div className="container">
                        <div className="row pb-3 text-center align-items-center">
                            <div className="col">
                                <h5 className="lh-1 mb-3">
                                    {translate(ds.coursePageSpeak.stringId, ds.coursePageSpeak.defaultString)}
                                </h5>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/US.svg"/>
                            </div>
                            <div className="col miaru-text">
                                <ArrowRightSquareFill size={40} />
                            </div>
                            <div className="col">
                                <h5 className="lh-1 mb-3">
                                    {translate(ds.coursePageLearn.stringId, ds.coursePageLearn.defaultString)}
                                </h5>
                                <img className="d-inline-block me-1"  width="60" height="40" src="assets/flags/JP.svg"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        <u>
                            {translate(ds.coursePageKorean.stringId, ds.coursePageKorean.defaultString)}
                        </u>
                    </h3>
                    <div className="container">
                        <div className="row pb-3 text-center align-items-center">
                            <div className="col">
                                <h5 className="lh-1 mb-3">
                                    {translate(ds.coursePageSpeak.stringId, ds.coursePageSpeak.defaultString)}
                                </h5>
                                <img className="d-inline-block me-1 py-1"  width="60" height="40" src="assets/flags/US.svg"/>
                            </div>
                            <div className="col miaru-text">
                                <ArrowRightSquareFill size={40} />
                            </div>
                            <div className="col">
                                <h5 className="lh-1 mb-3">
                                    {translate(ds.coursePageLearn.stringId, ds.coursePageLearn.defaultString)}
                                </h5>
                                <img className="d-inline-block me-1"  width="60" height="40" src="assets/flags/KR.svg"/>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </ErrorBoundary>
    );
};

export default CoursesPage;
