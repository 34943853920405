export const DefaultStrings = {
    // language switcher
    languageSwither: {stringId: "languageswitcher.selectorText", defaultString: "Site Language:"},
    languagePickerLabel: {stringId: "languagePicker.label", defaultString: "Language Picker"},
    english: {stringId: "languageswitcher.languages.us", defaultString: "English"},
    german: {stringId: "languageswitcher.languages.de", defaultString: "German"},
    chinese: {stringId: "languageswitcher.languages.cn", defaultString: "Chinese"},
    japanese: {stringId: "languageswitcher.languages.jp", defaultString: "Japanese"},
    korean: {stringId: "languageswitcher.languages.kr", defaultString: "Korean"},
    italian: {stringId: "languageswitcher.languages.it", defaultString: "Italian"},
    french: {stringId: "languageswitcher.languages.fr", defaultString: "French"},
    spanish: {stringId: "languageswitcher.languages.es", defaultString: "Spanish"},
    portugeas: {stringId: "languageswitcher.languages.pt", defaultString: "Portuguese"},
    russia: {stringId: "languageswitcher.languages.ru", defaultString: "Russian"},
    bosnian: {stringId: "languageswitcher.languages.ba", defaultString: "Bosnian"},
    serbian: {stringId: "languageswitcher.languages.rs", defaultString: "Serbian"},
    croatian: {stringId: "languageswitcher.languages.hr", defaultString: "Croatian"},
    slovenian: {stringId: "languageswitcher.languages.si", defaultString: "Slovenian"},
    romanian: {stringId: "languageswitcher.languages.ro", defaultString: "Romanian"},
    slovak: {stringId: "languageswitcher.languages.sk", defaultString: "Slovak"},
    bulgarian: {stringId: "languageswitcher.languages.bg", defaultString: "Bulgarian"},
    hungarian: {stringId: "languageswitcher.languages.hu", defaultString: "Hungarian"},
    ukrainian: {stringId: "languageswitcher.languages.ua", defaultString: "Ukrainian"},
    polish: {stringId: "languageswitcher.languages.pl", defaultString: "Polish"},
    belgiun: {stringId: "languageswitcher.languages.be", defaultString: "Dutch"},
    dutch: {stringId: "languageswitcher.languages.nl", defaultString: "Dutch"},
    czech: {stringId: "languageswitcher.languages.cz", defaultString: "Czech"},
    danish: {stringId: "languageswitcher.languages.dk", defaultString: "Danish"},
    norwegian: {stringId: "languageswitcher.languages.no", defaultString: "Norwegian"},
    swedish: {stringId: "languageswitcher.languages.se", defaultString: "Swedish"},
    finnish: {stringId: "languageswitcher.languages.fi", defaultString: "Finnish"},
    greek: {stringId: "languageswitcher.languages.gr", defaultString: "Greek"},
    turkish: {stringId: "languageswitcher.languages.tr", defaultString: "Turkish"},
    indonesian: {stringId: "languageswitcher.languages.id", defaultString: "Indonesian"},
    malay: {stringId: "languageswitcher.languages.my", defaultString: "Malay"},
    thai: {stringId: "languageswitcher.languages.th", defaultString: "Thai"},
    filipino: {stringId: "languageswitcher.languages.ph", defaultString: "Filipino"},
    vietnamese: {stringId: "languageswitcher.languages.vn", defaultString: "Vietnamese"},
    hindi: {stringId: "languageswitcher.languages.in", defaultString: "Hindi"},
    nepali: {stringId: "languageswitcher.languages.np", defaultString: "Nepali"},

    // ErrorPage
    errorPageHeading: {stringId: "errorPage.heading", defaultString: "Sorry"},
    errorPageSubHeading: {stringId: "errorPage.subheading", defaultString: "Something went wrong"},
    errorPageImgAltText: {stringId: "errorPage.imgAltText", defaultString: "Miaru Moscot Luffy looking sad with frown face and tear in right eye."},

    // Header
    headerTitle: {stringId: "header.title", defaultString: "Miaru"},

    // footer
    footerTitle:  {stringId: "footer.title", defaultString: "Miaru"},
    copyright:  {stringId: "footer.copyright", defaultString: "Copyright © Miaru {{year}}"},
    aboutus: {stringId: "footer.aboutus.heading", defaultString: "About Us"},
    courses: {stringId: "footer.aboutus.courses", defaultString: "Courses"},
    mission: {stringId: "footer.aboutus.mission", defaultString: "Mission"},
    team: {stringId: "footer.aboutus.team", defaultString: "Team"},
    career: {stringId: "footer.aboutus.career", defaultString: "Career"},
    brandguide: {stringId: "footer.aboutus.brandguide", defaultString: "Brand guidelines"},
    store: {stringId: "footer.aboutus.store", defaultString: "Store"},
    press: {stringId: "footer.aboutus.press", defaultString: "Press"},
    luffy: {stringId: "footer.aboutus.luffy", defaultString: "Mascot Luffy"},
    luffyNft: {stringId: "footer.aboutus.luffyNft", defaultString: "Luffy NFT Collection"},
    helpandsupport: {stringId: "footer.helpandsupport.heading", defaultString: "Help and Support"},
    faq: {stringId: "footer.helpandsupport.faq", defaultString: "Miaru FAQs"},
    contact: {stringId: "footer.helpandsupport.contact", defaultString: "Contact us"},
    apps: {stringId: "footer.apps.heading", defaultString: "Apps"},
    webApp: {stringId: "footer.apps.webApp", defaultString: "Miaru Wep App"},
    ios: {stringId: "footer.apps.ios", defaultString: "Miaru for iOS"},
    android: {stringId: "footer.apps.android", defaultString: "Miaru for Android"},
    privacyandterms: {stringId: "footer.privacyandterms.heading", defaultString: "Privacy and Terms"},
    community: {stringId: "footer.privacyandterms.community", defaultString: "Community Guidelines"},
    privacy: {stringId: "footer.privacyandterms.privacy", defaultString: "Privacy Policy"},
    terms: {stringId: "footer.privacyandterms.terms", defaultString: "Terms and Conditions"},
    social: {stringId: "footer.social.heading", defaultString: "Social"},
    blog: {stringId: "footer.social.blog", defaultString: "Blog"},
    facebook: {stringId: "footer.social.facebook", defaultString: "Facebook"},
    twitter: {stringId: "footer.social.twitter", defaultString: "X"},
    instagram: {stringId: "footer.social.instagram", defaultString: "Instagram"},
    youtube: {stringId: "footer.social.youtube", defaultString: "YouTube"},
    tiktok: {stringId: "footer.social.tiktok", defaultString: "TikTok"},
    discord: {stringId: "footer.social.discord", defaultString: "Discord"},

    // main front page start //
    // top section
    mainFrontPageTopSectionTitle: {stringId: "mainFrontPage.topSection.title", defaultString: "Miaru"},
    mainFrontPageTopSectionHeading: {stringId: "mainFrontPage.topSection.heading", defaultString: "Where Web3 Meets the Future of Language Learning"},
    mainFrontPageTopSectionSubHeading: {stringId: "mainFrontPage.topSection.subHeading", defaultString: "Miaru merges Web3 innovation with language education to bridge cultures and unite a global community of learners. Guided by Luffy, our lively mascot, we deliver fun, personalized lessons that transcend borders and empower everyone to connect worldwide."},
    mainFrontPageTopSectionImageAltText: {stringId: "mainFrontPage.topSection.imageAltText", defaultString: "Miaru Mascot Luffy floating in air and giggling with country flags in shapes of bubbles floating behind Luffy. Countries include China, Japan, Spain, Germany, Korea, Bosnia, Italy and many more."},
    // lifetime section
    mainFrontPagelifeSecHeading: {stringId: "mainFrontPage.lifeTimeMemberSection.heading", defaultString: "Unlock a Lifetime of Learning"},
    mainFrontPagelifeSecSubHeading: {stringId: "mainFrontPage.lifeTimeMemberSection.subHeading", defaultString: "Limited Lifetime Membership Available."},
    mainFrontPagelifeSecSubHeading2: {stringId: "mainFrontPage.lifeTimeMemberSection.subHeading2", defaultString: "Get Yours Today!"},
    mainFrontPagelifeSecText: {stringId: "mainFrontPage.lifeTimeMemberSection.text", defaultString: "The Miaru Lifetime Membership offers unlimited access to all current and future language courses."},
    mainFrontPagelifeSecButtonText: {stringId: "mainFrontPage.lifeTimeMemberSection.buttonText", defaultString: "Get Lifetime Membership Now!"},
    mainFrontPagelifeSecSaleBubbleText: {stringId: "mainFrontPage.lifeTimeMemberSection.saleBubbleText", defaultString: "Sale!!!"},
    mainFrontPagelifeSecMoreInfoLinkText: {stringId: "mainFrontPage.lifeTimeMemberSection.moreInfoLinkText", defaultString: "Learn more about Lifetime Membership"},
    mainFrontPagelifeSecImageAltText: {stringId: "mainFrontPage.lifeTimeMemberSection.imageAltText", defaultString: "Miaru Mascot Luffy with a party hat on floating while holding three baloons and confetti around him. He has a party blowout noisemaker in his mouth."},
    // mobile section
    mainFrontPageMobSecHeading: {stringId: "mainFrontPage.mobileSection.heading", defaultString: "Take Miaru on the Go"},
    mainFrontPageMobSecSubHeading: {stringId: "mainFrontPage.mobileSection.subHeading", defaultString: "Your lessons travel with you, wherever you go."},
    mainFrontPageMobSecText: {stringId: "mainFrontPage.mobileSection.text", defaultString: "Download our apps on the Apple App Store or Google Play Store and transform idle moments into learning opportunities. Turn waiting in line or your daily commute into your next step towards fluency."},
    mainFrontPageMobSecImageAltText: {stringId: "mainFrontPage.mobileSection.imageAltText", defaultString: "Miaru Mascot Luffy flying with a smart phone behind him about twice his size and four speech bubbles coming out of the phone with each bubble having a unique symbol from varies languages."},
    mainFrontPageMobSecCommingSoon: {stringId: "mainFrontPage.mobileSection.commingSoon", defaultString: "Coming Soon"},
    mainFrontPageMobSecAppleButtonTop: {stringId: "mainFrontPage.mobileSection.appleButtonTop", defaultString: "Download on the"},
    mainFrontPageMobSecAppleButtonBottom: {stringId: "mainFrontPage.mobileSection.appleButtonBottom", defaultString: "App Store"},
    mainFrontPageMobSecGoogleButtonTop: {stringId: "mainFrontPage.mobileSection.googleButtonTop", defaultString: "Get it on"},
    mainFrontPageMobSecGoogleButtonBottom: {stringId: "mainFrontPage.mobileSection.googleButtonBottom", defaultString: "Google Play"},
    // merch section 
    mainFrontPageMerchSecHeading: {stringId: "mainFrontPage.merchSection.heading", defaultString: "Wear Your Learning Journey"},
    mainFrontPageMerchSecSubHeading: {stringId: "mainFrontPage.merchSection.subHeading", defaultString: "Explore our merchandise shop for exclusive Miaru gear."},
    mainFrontPageMerchSecText: {stringId: "mainFrontPage.merchSection.text", defaultString: "From stylish t-shirts to handy notebooks, find everything you need to show off your dedication to language mastery and inspire others along the way."},
    mainFrontPageMerchSecShopButton: {stringId: "mainFrontPage.merchSection.shopButton", defaultString: "Shop Now!"},
    mainFrontPageMerchSecImageAltText: {stringId: "mainFrontPage.merchSection.imageAltText", defaultString: "Miaru Mascot Luffy showing a thumbs up with a T-shirt sample image behind him and a male model posing with the T-shit design."},
    // Luffy NFT section
    mainFrontPageLuffyNftSecHeading: {stringId: "mainFrontPage.luffyNft.heading", defaultString: "Empower Miaru's Future"},
    mainFrontPageLuffyNftSecSubHeading: {stringId: "mainFrontPage.luffyNft.subHeading", defaultString: "Luffy's NFT Collection"},
    mainFrontPageLuffyNftSecText: {stringId: "mainFrontPage.luffyNft.text", defaultString: "Embrace the adventure with Luffy’s NFTs and help us build the world’s largest learning community. By supporting this collection, you fuel Miaru’s growth as a Web3 education platform, bridging cultures and languages across the globe. Together, we can revolutionize how knowledge is shared—one NFT at a time."},
    mainFrontPageLuffyNftSecShopButton: {stringId: "mainFrontPage.luffyNft.shopButton", defaultString: "Discover The Collection"},
    mainFrontPageLuffyNftSecImageAltText: {stringId: "mainFrontPage.luffyNft.imageAltText", defaultString: "A combination of images of Miaru Mascot Character Luffy as you can see him in the Miaru Luffy NFT Collection."},
    // Adaptive AI section 
    mainFrontPageAiSecHeading: {stringId: "mainFrontPage.aiSection.heading", defaultString: "Miaru’s Adaptive, Scenario-Based Conversations"},
    mainFrontPageAiSecSubHeading: {stringId: "mainFrontPage.aiSection.subHeading", defaultString: "Learn with Luffy’s New Friend, Ai"},
    mainFrontPageAiSecText: {stringId: "mainFrontPage.aiSection.text", defaultString: "Engage with learning experiences curated to match your daily life, personal interests, and passions. Take the reins and shape your own educational journey."},
    mainFrontPageAiSecImageAltText: {stringId: "mainFrontPage.aiSection.imageAltText", defaultString: "Luffy with a bunch of Ai the A.I. robot around him."},


    // main front page end //

    // Course Page 
    coursePageTitle: {stringId: "coursePage.title", defaultString: "Language Courses"},
    coursePageSpeak: {stringId: "coursePage.course.speak", defaultString: "I speak ..."},
    coursePageLearn: {stringId: "coursePage.course.learn", defaultString: "I am learning ..."},
    coursePageEnglish: {stringId: "coursePage.course.english", defaultString: "English"},
    coursePageChinese: {stringId: "coursePage.course.chinese", defaultString: "Chinese"},
    coursePageJapanese: {stringId: "coursePage.course.japanese", defaultString: "Japanese"},
    coursePageKorean: {stringId: "coursePage.course.korean", defaultString: "Korean"},

    // FAQ Page 
    faqPageTitle: {stringId: "faqPage.title", defaultString: "FAQ"},
    faqPageGeneralHeading: {stringId: "faqPage.general.heading", defaultString: "Miaru General"},
    faqPageGeneralQ1: {stringId: "faqPage.general.q1", defaultString: "What's the inspiration behind the name Miaru?"},
    faqPageGeneralA1: {stringId: "faqPage.general.a1", defaultString: "The name Miaru holds sentimental value for our founder. It's an inventive fusion of the names of his two beloved daughters, symbolizing both unity and passion."},
    faqPageGeneralQ2: {stringId: "faqPage.general.q2", defaultString: "What's the story behind the mascot, and what is his name?"},
    faqPageGeneralA2: {stringId: "faqPage.general.a2", defaultString: "<a href='https://miaru.medium.com/miarus-mascot-luffy-b02175cd058b' target='_blank'>Meet Luffy</a>, our charming monkey mascot! Luffy isn't just any mascot; he carries with him a legacy. Our founder has incorporated Luffy into several past projects and products. Luffy's inclusion in Miaru is a continuation of this cherished tradition."},
    faqPageGeneralQ3: {stringId: "faqPage.general.q3", defaultString: "Can I suggest a new language or course?"},
    faqPageGeneralA3: {stringId: "faqPage.general.a4", defaultString: "Absolutely! We're always keen to enhance our offerings based on user feedback. If you have a language or course suggestion, please reach out to us directly at <a href='mailto:contact@miaru.co' target='_blank'>contact@miaru.co</a> or submit a ticket via our knowledge base portal. Your input is invaluable in our journey to deliver a comprehensive language learning experience."},
    faqPageLifetimeHeading: {stringId: "faqPage.lifetime.heading", defaultString: "Lifetime Subscription"},
    faqPageLifetimeQ1: {stringId: "faqPage.lifetime.Q1", defaultString: "What benefits are included in the Lifetime Subscription?"},
    faqPageLifetimeA1: {stringId: "faqPage.lifetime.A1", defaultString: "With the Lifetime Subscription, users are granted unlimited access to every language course available on Miaru. This encompasses both the Web App and Mobile Apps for iOS and Android platforms."},
    faqPageLifetimeQ2: {stringId: "faqPage.lifetime.Q2", defaultString: "Will new languages be added to my subscription in the future?"},
    faqPageLifetimeA2: {stringId: "faqPage.lifetime.A2", defaultString: "As Miaru evolves, we'll continually introduce new language courses. Rest assured, your Lifetime Subscription will encompass all future language courses, regardless of when they are launched."},
    faqPageLifetimeQ3: {stringId: "faqPage.lifetime.Q3", defaultString: "Are there any exclusions with the Lifetime Subscription?"},
    faqPageLifetimeA3: {stringId: "faqPage.lifetime.A3", defaultString: "The scope of the Lifetime Subscription is limited to the language learning courses on Miaru. It does not extend to potential new products that Miaru might introduce down the line. This could encompass offerings such as Video Tutor sessions, Language Certification Tests, Translation services, or any product distinct from our core Language Courses."},
    faqPageLifetimeQ4: {stringId: "faqPage.lifetime.Q4", defaultString: "Can I request a refund for my Lifetime Subscription?"},
    faqPageLifetimeA4: {stringId: "faqPage.lifetime.A4", defaultString: "Purchases of the Lifetime Subscription are conclusive, and we don't accommodate refund requests nor permit transitions to monthly or annual subscriptions. However, users have the option to sell their Lifetime Subscription. Detailed guidelines on this can be found in our 'Lifetime Subscription NFT' section."},
    faqPageLifetimeQ5: {stringId: "faqPage.lifetime.Q5", defaultString: "How can I sell my Lifetime Subscription?"},
    faqPageLifetimeA5: {stringId: "faqPage.lifetime.A5", defaultString: "Miaru has innovatively integrated NFTs with the Lifetime Subscription, available for access on your profile page within the Miaru app."},
    faqPageLifetimeQ6: {stringId: "faqPage.lifetime.Q6", defaultString: "What exactly is a Lifetime Subscription NFT?"},
    faqPageLifetimeA6: {stringId: "faqPage.lifetime.A6", defaultString: "A Lifetime Subscription NFT acts as a digital asset on the blockchain, symbolizing a user's Lifetime Subscription to Miaru."},
    faqPageLifetimeQ7: {stringId: "faqPage.lifetime.Q7", defaultString: "What functionalities does the NFT offer?"},
    faqPageLifetimeA7: {stringId: "faqPage.lifetime.A7", defaultString: "The Lifetime Subscription NFT offers a suite of utilities:"},
    faqPageLifetimeA7B1: {stringId: "faqPage.lifetime.A7B1", defaultString: "Withdrawal: You can extract the Lifetime Subscription from your Miaru profile. Following this, your Miaru account will no longer possess the Lifetime Subscription."},
    faqPageLifetimeA7B2: {stringId: "faqPage.lifetime.A7B2", defaultString: "Import: If procured from an NFT marketplace, you can assimilate a Lifetime Subscription NFT into your Miaru account, activating the Lifetime Subscription for your profile."},
    faqPageLifetimeA7B3: {stringId: "faqPage.lifetime.A7B3", defaultString: "Resale: Post withdrawing your Lifetime Subscription NFT from Miaru, it becomes eligible for listing on an NFT Marketplace. A sale transfers the ownership of the NFT."},
    faqPageLifetimeQ8: {stringId: "faqPage.lifetime.Q8", defaultString: "Which blockchain hosts the Lifetime Subscription NFT?"},
    faqPageLifetimeA8: {stringId: "faqPage.lifetime.A8", defaultString: "As of October 2023, our intent is to anchor the NFT assets on the <a href='https://hedera.com/' target='_blank'>Hedera blockchain.</a>"},
    faqPageLifetimeQ9: {stringId: "faqPage.lifetime.Q9", defaultString: "What if I mistakenly transferred my Lifetime Subscription NFT to an incorrect wallet?"},
    faqPageLifetimeA9: {stringId: "faqPage.lifetime.A9", defaultString: "Post-withdrawal from Miaru, we relinquish control over the NFT. Consequently, any mishaps or losses post-withdrawal fall under user liability. It's imperative to exercise caution during blockchain transactions and consistently verify addresses."},
    faqPageMonthlyHeading: {stringId: "faqPage.monthly.heading", defaultString: "Monthly Subscription"},
    faqPageMonthlyQ1: {stringId: "faqPage.monthly.Q1", defaultString: "What does the Monthly Subscription encompass?"},
    faqPageMonthlyA1: {stringId: "faqPage.monthly.A1", defaultString: "The Monthly Subscription entitles users to unrestricted access to the entire suite of Language Courses available on Miaru."},
    faqPageMonthlyQ2: {stringId: "faqPage.monthly.Q2", defaultString: "Can I transition from a Monthly Subscription to a Yearly Subscription?"},
    faqPageMonthlyA2: {stringId: "faqPage.monthly.A2", defaultString: "Certainly. Users have the flexibility to modify their subscription model as per their preference. Such changes can be effortlessly managed via the 'Subscription Settings' in your Miaru account."},
    faqPageMonthlyQ3: {stringId: "faqPage.monthly.Q3", defaultString: "What if I wish to terminate and seek a refund for my monthly subscription?"},
    faqPageMonthlyA3: {stringId: "faqPage.monthly.A3", defaultString: "While subscribers have the liberty to terminate their subscription at any given time, please note that refunds are not facilitated. Nonetheless, access to Miaru's offerings under the respective subscription will persist until the current billing cycle concludes. For instance:"},
    faqPageMonthlyA3B1: {stringId: "faqPage.monthly.A3B1", defaultString: "Subscription initiation: October 15th, 2023"},
    faqPageMonthlyA3B2: {stringId: "faqPage.monthly.A3B2", defaultString: "Cancellation request: October 20th, 2023"},
    faqPageMonthlyA3B3: {stringId: "faqPage.monthly.A3B3", defaultString: "Continued access until: November 15th, 2023"},
    faqPageYearlyHeading: {stringId: "faqPage.yearly.heading", defaultString: "Yearly Subscription"},
    faqPageYearlyQ1: {stringId: "faqPage.yearly.Q1", defaultString: "What encompasses the Yearly Subscription?"},
    faqPageYearlyA1: {stringId: "faqPage.yearly.A1", defaultString: "The Yearly Subscription provides users with an uninterrupted, year-long access to all the Language Courses offered by Miaru."},
    faqPageYearlyQ2: {stringId: "faqPage.yearly.Q2", defaultString: "Is it possible to switch from a Yearly to a Monthly Subscription?"},
    faqPageYearlyA2: {stringId: "faqPage.yearly.A2", defaultString: "Absolutely. Subscribers have the liberty to modify their subscription preferences at any point during their subscription term. Such transitions can be effortlessly executed through the 'Subscription Settings' on your Miaru account dashboard."},
    faqPageYearlyQ3: {stringId: "faqPage.yearly.Q3", defaultString: "What is the procedure and policy for canceling and seeking a refund for my yearly subscription?"},
    faqPageYearlyA3: {stringId: "faqPage.yearly.A3", defaultString: "While we provide subscribers the option to terminate their subscription at their discretion, it's essential to note that refunds aren't extended under our policy. However, the benefits and access related to your subscription remain active until the end of the billing cycle. Here's a clarifying example:"},
    faqPageYearlyA3B1: {stringId: "faqPage.yearly.A3B1", defaultString: "Subscription initiation: October 15th, 2022"},
    faqPageYearlyA3B2: {stringId: "faqPage.yearly.A3B2", defaultString: "Cancellation request: May 20th, 2023"},
    faqPageYearlyA3B3: {stringId: "faqPage.yearly.A3B3", defaultString: "Continuation of access until: October 15th, 2023"},
    faqPageMerchHeading: {stringId: "faqPage.merch.heading", defaultString: "Merchandise Store"},
    faqPageMerchQ1: {stringId: "faqPage.merch.Q1", defaultString: "Is all the merchandise produced directly by Miaru?"},
    faqPageMerchA1: {stringId: "faqPage.merch.A1", defaultString: "While we ensure the utmost quality in our merchandise, we do not directly produce them. Instead, we've partnered with a reputable print-on-demand service provider. This means items are crafted once an order has been placed."},
    faqPageMerchQ2: {stringId: "faqPage.merch.Q2", defaultString: "Why does the merchandise seem comparatively pricey?"},
    faqPageMerchA2: {stringId: "faqPage.merch.A2", defaultString: "Our merchandise pricing is reflective of the print-on-demand model we've adopted. Given that products are uniquely produced upon each order, certain costs are naturally associated."},
    faqPageMerchQ3: {stringId: "faqPage.merch.Q3", defaultString: "What is the rationale behind opting for a print-on-demand model?"},
    faqPageMerchA3: {stringId: "faqPage.merch.A3", defaultString: "Miaru's core focus is delivering unparalleled language learning content. Consequently, our resources and energies are channeled towards that mission. While we are eager to cater to our users and fans with merchandise options, managing a full-scale merchandise division demands significant commitment in terms of time and capital. The print-on-demand approach emerged as an optimal solution, aligning with our resources and ensuring no upfront costs to Miaru."},
    faqPageMerchQ4: {stringId: "faqPage.merch.Q4", defaultString: "I'm dissatisfied with my purchase, either due to size or design. Is there a possibility for a refund?"},
    faqPageMerchA4: {stringId: "faqPage.merch.A4", defaultString: "We deeply regret any inconvenience. However, given the bespoke nature of our print-on-demand model, we're currently unable to process refunds for merchandise. All sales, in this regard, are deemed final."},
    faqPageMobileHeading: {stringId: "faqPage.mobile.heading", defaultString: "Mobile Apps iOS and Android"},
    faqPageMobileQ1: {stringId: "faqPage.mobile.Q1", defaultString: "Is there any disparity between the Web App and Mobile Apps in terms of content access?"},
    faqPageMobileA1: {stringId: "faqPage.mobile.A1", defaultString: "The Mobile Apps for iOS and Android mirror the Web App in terms of content accessibility. Everything available on the Web App can seamlessly be accessed on the Mobile Apps."},
    faqPageMobileQ2: {stringId: "faqPage.mobile.Q2", defaultString: "Will my subscription encompass access to both the Web App and Mobile Apps?"},
    faqPageMobileA2: {stringId: "faqPage.mobile.A2", defaultString: "Indeed. Any subscription availed for Miaru's Web App automatically grants you equivalent access on the Mobile Apps, ensuring a unified experience across platforms."},
    faqPageMobileQ3: {stringId: "faqPage.mobile.Q3", defaultString: "What's the reasoning behind offering native mobile apps?"},
    faqPageMobileA3: {stringId: "faqPage.mobile.A3", defaultString: "A sizable portion of users express a marked preference for native mobile apps over mobile browsers, such as Safari or Chrome. Native apps often offer enhanced responsiveness, intuitive user interfaces, and circumvent certain limitations inherent to mobile browsers. To provide an optimal and enriching user experience, Miaru has taken the initiative to develop dedicated native apps for both iOS and Android platforms."},
    
    faqPageNFTHeading: {stringId: "faqPage.nft.heading", defaultString: "Luffy NFT Collection"},
    faqPageNFTQ1: {stringId: "faqPage.nft.Q1", defaultString: "What does it mean that you are crowdfunding with an NFT collection?"},
    faqPageNFTA1: {stringId: "faqPage.nft.A1", defaultString: "Miaru has been self-funded throughout its existence, relying solely on internal resources without seeking external investors or venture capital. This approach, however, is starting to limit our growth. While we considered platforms like Kickstarter or IndieGoGo for crowdfunding, their 'all or nothing' model did not align with our vision. As a result, we've chosen to use NFTs as a direct and innovative crowdfunding method, eliminating the need for intermediaries between Miaru and our supporters."},
    faqPageNFTQ2: {stringId: "faqPage.nft.Q2", defaultString: "By participating in the crowdfunding, do I gain equity in Miaru?"},
    faqPageNFTA2: {stringId: "faqPage.nft.A2", defaultString: "No, participants in the crowdfunding will not receive equity in Miaru. Our campaign is based on reward-based crowdfunding, where contributors are offered specific rewards instead of equity in the company."},
    faqPageNFTQ3: {stringId: "faqPage.nft.Q3", defaultString: "What is reward-based crowdfunding?"},
    faqPageNFTA3: {stringId: "faqPage.nft.A3", defaultString: "Reward-based crowdfunding allows startups to raise funds and gauge public interest in their products. In return for financial contributions, startups offer rewards to their supporters."},
    faqPageNFTQ4: {stringId: "faqPage.nft.Q4", defaultString: "What rewards can contributors expect?"},
    faqPageNFTA4: {stringId: "faqPage.nft.A4", defaultString: "Contributors can look forward to a variety of rewards, including:"},
    faqPageNFTA4B1: {stringId: "faqPage.nft.A4B1", defaultString: "A free 1-month subscription to Miaru, enhancing your language learning journey."},
    faqPageNFTA4B2: {stringId: "faqPage.nft.A4B2", defaultString: "A 20% discount on all Miaru merchandise, allowing you to show your support in style."},
    faqPageNFTA4B3: {stringId: "faqPage.nft.A4B3", defaultString: "Exclusive 20% discount for a lifetime subscription to Miaru."},
    faqPageNFTA4B4: {stringId: "faqPage.nft.A4B4", defaultString: "Access to a members-only section of our Discord channel, connecting you with fellow language enthusiasts."},
    faqPageNFTA4B5: {stringId: "faqPage.nft.A4B5", defaultString: "Early access to alpha/beta releases of our apps, giving you the opportunity to shape Miaru's future."},
    faqPageNFTA4B6: {stringId: "faqPage.nft.A4B6", defaultString: "The chance to become a beta tester, providing valuable feedback to enhance user experience."},
    faqPageNFTA4B7: {stringId: "faqPage.nft.A4B7", defaultString: "Early access to our affiliate program, offering a chance to benefit alongside Miaru."},
    faqPageNFTQ5: {stringId: "faqPage.nft.Q5", defaultString: "What is your fundraising goal?"},
    faqPageNFTA5: {stringId: "faqPage.nft.A5", defaultString: "We aim to raise $500,000 US Dollars to support Miaru's growth and development."},
    faqPageNFTQ6: {stringId: "faqPage.nft.Q6", defaultString: "What is an NFT?"},
    faqPageNFTA6: {stringId: "faqPage.nft.A6", defaultString: "An NFT, or Non-fungible Token, represents a unique item that cannot be replaced. In our case, it refers to digital artwork featuring our mascot character, Luffy, signifying its uniqueness and value."},
    faqPageNFTQ7: {stringId: "faqPage.nft.Q7", defaultString: "On which blockchain will the NFTs be minted?"},
    faqPageNFTA7: {stringId: "faqPage.nft.A7", defaultString: "Our NFT series is set to be issued on mulltiple blockchains, we will be holding a vote the closer we get to release for which blockchain to prioritize."},
    faqPageNFTQ8: {stringId: "faqPage.nft.Q8", defaultString: "How many NFTs will be in the collection?"},
    faqPageNFTA8: {stringId: "faqPage.nft.A8", defaultString: "The collection will comprise 10,000 NFTs, featuring 5 different poses of our mascot Luffy. Each pose will be represented by 2,000 NFTs, totaling 10,000 NFTs."},
    faqPageNFTQ9: {stringId: "faqPage.nft.Q9", defaultString: "Will Miaru collect royalties on the NFTs?"},
    faqPageNFTA9: {stringId: "faqPage.nft.A9", defaultString: "No, Miaru will not collect any royalties from sales of the NFTs after their initial minting."},
    faqPageNFTQ10: {stringId: "faqPage.nft.Q10", defaultString: "Does owning an NFT from the Luffy Collection grant me copyright ownership of Luffy?"},
    faqPageNFTA10: {stringId: "faqPage.nft.A10", defaultString: "No, possessing an NFT from the Luffy Collection does not give you any copyright or intellectual property rights to Luffy. Miaru LLC remains the sole owner of everything associated with Miaru and its mascot, Luffy."},
    
    
    faqPageIssue: {stringId: "faqPage.issue", defaultString: "Have a problem?"},
    faqPageIssueSubText: {stringId: "faqPage.issueSubText", defaultString: "Were you not able to find an answer in the FAQ? Submit a ticket and we will get back to you."},
    faqPageIssueButton: {stringId: "faqPage.issueButton", defaultString: "Open Ticket"},
    // Privacy Policy Page 
    privacyPageTitle: {stringId: "privacyPage.title", defaultString: "Privacy Policy"},

    // Terms and Condition Page 
    termsPageTitle: {stringId: "termsPage.title", defaultString: "Terms and Conditions"},

    // Team Page 
    teamPageTitle: {stringId: "teamPage.team.title", defaultString: "Meet Our Team"},
    teamPageText: {stringId: "teamPage.team.text", defaultString: "At Miaru, we believe in the power of diversity and collaboration. Our team is a global family of individuals with a shared passion for language and education. We come from different corners of the world, bringing unique perspectives and a multitude of languages to the table. Together, we're dedicated to helping you unlock the world of language learning."},
    teamPageImg: {stringId: "teamPage.team.img", defaultString: "Miaru Mascot Luffy standing."},
    teamPageMissionTitle: {stringId: "teamPage.mission.title", defaultString: "Our Mission"},
    teamPageMissionText: {stringId: "teamPage.mission.text", defaultString: "At Miaru, our mission is to break down the barriers to language learning. We believe that language is the key to understanding and connecting with people from different cultures, and we're here to make that journey accessible to everyone. Our commitment to you is:"},
    teamPageMissionTextB1: {stringId: "teamPage.mission.textB1", defaultString: "Accessibility: We strive to provide affordable and language learning resources that cater to all skill levels."},
    teamPageMissionTextB2: {stringId: "teamPage.mission.textB2", defaultString: "Innovation: We continuously explore new technologies and teaching methods to enhance your learning experience."},
    teamPageMissionTextB3: {stringId: "teamPage.mission.textB3", defaultString: "Community: We're building a global community of language enthusiasts who share their experiences, challenges, and successes."},
    teamPageMissionSecondText: {stringId: "teamPage.mission.text2", defaultString: "As we look ahead, we're excited about the potential to expand our mission into other areas of education, always with the goal of making learning more accessible and enjoyable. The future holds endless possibilities, and we can't wait to embark on this journey with you."},
    teamPageMissionThirdText: {stringId: "teamPage.mission.text3", defaultString: "Thank you for being a part of the Miaru family."},
    teamPageMissionImg: {stringId: "teamPage.mission.img", defaultString: "Miaru Mascot Luffy all dressed for adventure. Map in hand and backpack packed."},

    // Brand Page 
    brandPageTitle: {stringId: "brandPage.title", defaultString: "Brand Guidelines"},
    brandPageLogo: {stringId: "brandPage.logo", defaultString: "Miaru Logo"},
    brandPageColor: {stringId: "brandPage.color", defaultString: "Brand Colors"},

    // Press Page 
    pressPageTitle: {stringId: "pressPage.title", defaultString: "Recent Press Releases"},
    pressPagePressKitHeading: {stringId: "pressPage.presskit.heading", defaultString: "Press Kit"},
    pressPagePressKitSubHeading: {stringId: "pressPage.presskit.subheading", defaultString: "Download our logos, app screenshots, press images and more."},
    pressPagePressKitLinkText: {stringId: "pressPage.presskit.linkText", defaultString: "Download Press Kit"},

    // Luffy NFT Page 
    luffyNftPageNFTImg: {stringId: "luffyNftPage.img", defaultString: "Miaru NFT Collection Sample Image."},
    luffyNftPageMiaruImg: {stringId: "luffyNftPage.miaruImg", defaultString: "Miaru"},
    luffyNftPageSec1Title: {stringId: "luffyNftPage.sec1.title", defaultString: "NFT Collection"},
    luffyNftPageSec1SubHeading: {stringId: "luffyNftPage.sec1.subHeading", defaultString: "Crowdfunding Through Creativity"},
    luffyNftPageSec1Text: {stringId: "luffyNftPage.sec1.text", defaultString: "Welcome to the groundbreaking initiative that combines the love of language learning with the world of digital art. We've launched the Luffy NFT collection not just as a means to crowdfund for Miaru, but to do so in a way that rewards our supporters uniquely and memorably. Through reward-based crowdfunding, we aim to enhance and expand Miaru without leaving our donors empty-handed. Each NFT of our beloved mascot, Luffy, symbolizes our appreciation and your invaluable contribution to our mission. Join us in this innovative adventure to revolutionize language learning."},
    luffyNftPageSec1linkText: {stringId: "luffyNftPage.sec1.linkText", defaultString: "Collect Your Luffy NFT!"},
    luffyNftPageSec2Title: {stringId: "luffyNftPage.sec2.title", defaultString: "Exclusive Rewards Await"},
    luffyNftPageSec2SubHeading: {stringId: "luffyNftPage.sec2.subHeading", defaultString: "Your Support, Our Gratitude"},
    luffyNftPageSec2Text: {stringId: "luffyNftPage.sec2.text", defaultString: "Supporting Miaru's vision comes with its perks. By participating in our Luffy NFT collection, you unlock a treasure trove of rewards designed to enhance your Miaru experience:"},
    luffyNftPageSec2TextB1: {stringId: "luffyNftPage.sec2.textB1", defaultString: "Enjoy a free 1-month subscription to Miaru, diving deep into language learning."},
    luffyNftPageSec2TextB2: {stringId: "luffyNftPage.sec2.textB2", defaultString: "Receive a 20% discount on all Miaru Merchandise, showcasing your support with style."},
    luffyNftPageSec2TextB3: {stringId: "luffyNftPage.sec2.textB3", defaultString: "Exclusive 20% discount for a lifetime subscription to Miaru."},
    luffyNftPageSec2TextB4: {stringId: "luffyNftPage.sec2.textB4", defaultString: "Access a members-only section on our Discord channel, joining a community of language enthusiasts."},
    luffyNftPageSec2TextB5: {stringId: "luffyNftPage.sec2.textB5", defaultString: "Get early access to alpha/beta releases of our apps, shaping the future of Miaru."},
    luffyNftPageSec2TextB6: {stringId: "luffyNftPage.sec2.textB6", defaultString: "Become a beta tester, providing valuable feedback to improve user experience."},
    luffyNftPageSec2TextB7: {stringId: "luffyNftPage.sec2.textB7", defaultString: "Early access to our affiliate program before the public launch, offering you a chance to benefit alongside Miaru."},
    luffyNftPageSec3Title: {stringId: "luffyNftPage.sec3.title", defaultString: "A Universe of Uniqueness"},
    luffyNftPageSec3SubHeading: {stringId: "luffyNftPage.sec3.subHeading", defaultString: "10,000 Ways to Connect"},
    luffyNftPageSec3Text: {stringId: "luffyNftPage.sec3.text", defaultString: "Dive into the diverse universe of the Luffy NFT collection, featuring 10,000 unique tokens that embody the spirit of Miaru. With 5 different poses of Luffy and over 50 traits for each pose, the variety and uniqueness of this collection are unmatched. Each NFT is a piece of art, a token of support, and a step towards making language learning accessible and engaging for everyone. Embrace the opportunity to own a unique part of Miaru's journey and be part of a community that values innovation, education, and creativity."},
    luffyNftPageSec3LinkText: {stringId: "luffyNftPage.sec3.linkText", defaultString: "Visit Our FAQ for Detailed Luffy NFT Information"},
    luffyNftPageActionHeading: {stringId: "luffyNftPage.action.heading", defaultString: "What are you waiting for?"},
    luffyNftPageActionSubHeading: {stringId: "luffyNftPage.action.subheading", defaultString: "Get your Luffy NFT today and be part of Miaru's innovative journey."},
    luffyNftPageActionLinkText: {stringId: "luffyNftPage.action.linkText", defaultString: "Get Your NFT"},

    // Luffy Page
    luffyPageSec1Title: {stringId: "luffyPage.sec1.title", defaultString: "Meet Your New Pal, Luffy!"},
    luffyPageSec1SubHeading: {stringId: "luffyPage.sec1.subHeading", defaultString: "Navigate Miaru's Language Courses with Your New Friend"},
    luffyPageSec1Text: {stringId: "luffyPage.sec1.text", defaultString: "Meet Luffy, the charming guide designed to enhance your experience with Miaru's language programs. Drawing inspiration from the vibrant world of Japanese Anime Otaku culture, Luffy embodies our brand's vision: transforming language learning into a fun, engaging, and inclusive journey inspired by diverse cultures. More than just a mascot, Luffy represents the essence of Miaru, offering unwavering support for learners as they embark on the rewarding path to language proficiency. With a foundation in the playful and explorative spirit of our founders' childhoods, Luffy's presence enriches Miaru's educational content. Users will feel Luffy's guiding hand throughout their journey on the app, making every step in language learning an adventure."},
    luffyPageSec2Title: {stringId: "luffyPage.sec2.title", defaultString: "Luffy's Enduring Legacy"},
    luffyPageSec2SubHeading: {stringId: "luffyPage.sec2.subHeading", defaultString: "A Decade of Evolution with Luffy"},
    luffyPageSec2Text: {stringId: "luffyPage.sec2.text", defaultString: "Luffy's story began over a decade ago with OtakuSnap, an app celebrating Anime and Japanese culture. As the cheerful face of OtakuSnap, he embodied the fusion of vibrant pop culture and technology. Though OtakuSnap has since faded, Luffy found new life in the gaming world as the lead of OtakuRun, proving his adaptability and appeal. His journey through various creative ventures speaks to the depth of his character. Now, Luffy is poised for his latest mission: elevating Miaru to become the premier language learning app, leveraging his rich legacy to engage and inspire learners worldwide."},
    luffyPageSec3Title: {stringId: "luffyPage.sec3.title", defaultString: "Explore Luffy's Exclusive NFT Collection"},
    luffyPageSec3SubHeading: {stringId: "luffyPage.sec3.subHeading", defaultString: "Brining NFT's to learners "},
    luffyPageSec3Text: {stringId: "luffyPage.sec3.text", defaultString: "Luffy's charm has transcended into the digital realm with an exclusive NFT collection, designed to captivate fans and collectors alike. This unique series not only celebrates Luffy's iconic status but also serves as an innovative crowdfunding strategy for Miaru."},
    luffyPageSec3LinkText: {stringId: "luffyPage.sec3.linkText", defaultString: "Get Yours Now!"},
    luffyPageSec1Img: {stringId: "luffyPage.sec1.img", defaultString: "A image showing how Mascot Character Luffy was designed from sketches to final version."},
    luffyPageSec2Img: {stringId: "luffyPage.sec2.img", defaultString: "A image showing Mascot Character Luffy in Apps Otakusnap and OtakuRun."},
    luffyPageSec3Img: {stringId: "luffyPage.sec3.img", defaultString: "Miaru NFT Collection Sample Image."},
    luffyPageImg: {stringId: "luffyPage.img", defaultString: "Diverse Gallery of Luffy, the Mascot Character."},
};