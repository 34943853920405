import React from "react";
import { BrowserRouter, Route, Switch} from "react-router-dom";
import {Spinner} from 'reactstrap';
import { Links } from "src/common/Links";
import AppPage from "src/components/AppPage";
import ErrorPage from "src/components/ErrorPage";
import Footer from "src/components/footer/Footer";
import Header from "src/components/header/Header";
import BrandPage from "src/components/pages/BrandPage";
import CoursesPage from "src/components/pages/CoursesPage";
import FAQPage from "src/components/pages/FAQPage";
import LuffyNftPage from "src/components/pages/LuffyNftPage";
import LuffyPage from "src/components/pages/LuffyPage";
import PressPage from "src/components/pages/PressPage";
import PrivacyPage from "src/components/pages/PrivacyPage";
import TeamPage from "src/components/pages/TeamPage";
import TermsPage from "src/components/pages/TermsPage";


const Router = () => {
    return (
        <>
            <Header />
            <section className="miaru-main-section">
                    <React.Suspense fallback={<Spinner color="primary">Loading...</Spinner>}>
                        <BrowserRouter>
                            <Switch>
                                <Route exact={true} path={Links.home} component={AppPage} />
                                <Route exact={true} path={Links.courses} component={CoursesPage} />
                                <Route exact={true} path={Links.faq} component={FAQPage} />
                                <Route exact={true} path={Links.brand} component={BrandPage} />
                                <Route exact={true} path={Links.press} component={PressPage} />
                                <Route exact={true} path={Links.luffyNft} component={LuffyNftPage} />
                                <Route exact={true} path={Links.luffy} component={LuffyPage} />
                                <Route exact={true} path={Links.team} component={TeamPage} />
                                <Route exact={true} path={Links.privacy} component={PrivacyPage} />
                                <Route exact={true} path={Links.terms} component={TermsPage} />
                                <Route path={"*"} component={ErrorPage} />
                            </Switch>
                        </BrowserRouter>
                    </React.Suspense>
            </section>
            <Footer />
        </>
    );
};

export default Router;